@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url(https://fonts.gstatic.com/s/roboto/v20/KFOiCnqEu92Fr1Mu51QrEzAdKg.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TjASc6CsE.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1Mu51xIIzc.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51S7ACc6CsE.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TzBic6CsE.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: local('Roboto Black Italic'), local('Roboto-BlackItalic'), url(https://fonts.gstatic.com/s/roboto/v20/KFOjCnqEu92Fr1Mu51TLBCc6CsE.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url(https://fonts.gstatic.com/s/roboto/v20/KFOkCnqEu92Fr1MmgVxIIzc.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: local('Roboto Black'), local('Roboto-Black'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmYUtfBBc9.ttf) format('truetype');
}
body {
  margin: 0;
  font-family: 'Roboto', 'Arial', 'Helvetica', 'Ubuntu', 'Noto';
  color: #262626;
}
.cursor {
  z-index: 1;
  position: fixed;
  opacity: 0;
  bottom: -100px;
}
@media (pointer: fine) {
  .cursor {
    opacity: 1;
  }
}
.container {
  height: calc(var(--vh, 1vh) * 100);
  width: calc(100vh - (100vh - 100%));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: repeating-linear-gradient(to right, #ffffff 0, #ffffff 10px, #D8D8D8 11px, #ffffff 12px, #ffffff 40px);
  z-index: 0;
}
.container .overlay {
  width: 50%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  background: rgba(0, 0, 0, 0.83);
  backdrop-filter: blur(4px);
  position: absolute;
  left: 0;
  z-index: 1;
  box-shadow: 0 0 15px #00000060;
}
.container *:not(.overlay),
.container *:not(.cursor) {
  z-index: 2;
}
.container header {
  font-size: 188px;
  display: flex;
  flex-direction: column;
  margin: auto;
  pointer-events: none;
}
@media screen and (max-width: 500px) {
  .container header {
    font-size: 120px;
  }
}
.container header .line {
  display: flex;
}
.container header .line:nth-of-type(2) {
  margin-top: -50px;
}
@media screen and (max-width: 500px) {
  .container header .line:nth-of-type(2) {
    margin-top: -20px;
  }
}
.container header .line .m,
.container header .line .z {
  color: #fff;
}
.container header .line .m {
  margin-right: 50px;
  margin-left: -30px;
}
@media screen and (max-width: 500px) {
  .container header .line .m {
    margin-right: 50px;
    margin-left: -20px;
  }
}
.container header .line .z {
  margin-right: 80px;
  margin-left: -10px;
}
@media screen and (max-width: 500px) {
  .container header .line .z {
    margin-right: 60px;
    margin-left: 0;
  }
}
.container .subtitle {
  pointer-events: none;
  margin-bottom: auto;
}
.container .subtitle .line {
  text-transform: uppercase;
  font-size: 20px;
}
.container .subtitle .line:nth-child(odd) {
  color: #fff;
  text-align: right;
  transform: translateX(-50%);
  margin-right: 20px;
}
.container .subtitle .line:nth-child(even) {
  text-align: left;
  transform: translateX(50%);
  margin-left: 20px;
}
.container .social {
  margin-bottom: auto;
}
.container .social a {
  margin: 10px;
  text-decoration: none;
}
/*# sourceMappingURL=./style.css.map */